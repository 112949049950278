<template>
  <div>
    <span class="pl-2 pr-3 subtitle-1 font-weight-bold">{{ $t('groups') }}</span>
    <div>
      <v-list>
        <vuedraggable v-model="groups" handle=".handle">
          <map-group-list-item
            v-for="(group, i) in groups"
            :group="group"
            :isAddFeaturesAllowed="!!selectedFeatures.length"
            :map="map"
            dense
            @addFeatures="addFeatures(group)"
            @applyEtFilter="applyEtFilter(group)"
            @findRelatedFeatures="rel => findRelatedFeatures(group, rel)"
            @removeGroup="removeGroup(i)"
            @selectGroup="selectGroup(group)"
          />
        </vuedraggable>
      </v-list>
    </div>
    <portal to="groups-layer">
      <map-group-features
        v-for="group in groups"
        :features="group.features"
        :fillColor="group.fillColor"
        :strokeColor="group.strokeColor"
        :visible="group.visible"
      />
    </portal>

    <feature-color-picker
      ref="featureColorPicker"
    />
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import MapGroupFeatures from '@/components/map/groups/MapGroupFeatures.vue'
import FeatureColorPicker from '@/components/map/groups/MapGroupSettingsDialog.vue'
import MapGroupListItem from '@/components/map/groups/MapGroupListItem.vue'
import Vuedraggable from 'vuedraggable'
import zwsCommandBuilder from '@/services/zws-command-builder'
import messages from '@/componet-locale/map-groups/messages'

const DEFAULT_COLORS = {
  strokeColor: '#9900ffff',
  fillColor: '#9900ff22'
}

const SECONDARY_COLORS = {
  strokeColor: '#ff8133ff',
  fillColor: '#ff813322'
}

export default {
  name: "map-groups-bar",
  i18n: { messages },
  components: { Vuedraggable, MapGroupListItem, FeatureColorPicker, MapGroupFeatures },
  props: {
    map: Object,
    selectedFeatures: Array
  },
  data: () => ({
    groups: [],
    relatedFeaturesLoading: false
  }),
  mounted () {
    EventBus.$on('addMapGroup', this.addGroup)
    const initialValue = this.$store.getters.getMapGroups(this.map.id)
    if (initialValue && initialValue.groups) this.groups = JSON.parse(JSON.stringify(initialValue.groups))
  },
  methods: {
    addGroup (features, colors = DEFAULT_COLORS, name) {
      this.groups.push({
        features,
        ...colors,
        name,
        visible: true
      })
      EventBus.$emit('showInfoMessage', 'Группа создана')
    },
    selectGroup (group) {
      if (group.visible) {
        EventBus.$emit('selectFeatures', group.features)
      }
    },
    removeGroup (index) {
      this.groups.splice(index, 1)
    },
    addFeatures (group) {
      group.features.push(...this.selectedFeatures)
    },
    async findRelatedFeatures (group, relation) {
      const relationPropertyName = relation.relationPropertyName.replace(/.*:/, '')

      const sourceFeatures = group.features
        .filter(el => el.layer.id === relation.sourceLayer.id)
      if (sourceFeatures.length === 0) return EventBus.$emit('showInfoMessage', this.$t('noRelatedFeatures'))
      this.relatedFeaturesLoading = true
      const relatedPropertyList = await zwsCommandBuilder.queryForListBySysIn(
        relation.sourceLayer,
        relationPropertyName,
        sourceFeatures.map(it => it.elemId)
      )
      const relatedFeaturesIds = relatedPropertyList
        .map(it => it[relationPropertyName] && it[relationPropertyName].value)
        .filter(el => el)
      if (relatedFeaturesIds.length === 0) {
        this.relatedFeaturesLoading = false
        return EventBus.$emit('showInfoMessage', this.$t('noRelatedFeatures'))
      }
      await zwsCommandBuilder.getLayerTypes(relation.targetLayer)
      await zwsCommandBuilder.getElemsByID(relation.targetLayer, relatedFeaturesIds, null, {
        excludeGeometry: false,
        excludeQueryList: true,
        excludeModeList: true,
        excludeProps: true
      })
        .then(features => this.addGroup(features, SECONDARY_COLORS))
        .finally(() => this.relatedFeaturesLoading = false)
    },
    applyEtFilter (group) {
      const layer = group.features[0] && group.features[0].layer
      if (!layer || !['ZWS', 'LAYER_POI'].includes(layer.type)) return
      EventBus.$emit('applyEtFilterByFeatureIds', [{
        layerId: layer.id,
        featureIds: group.features.map(f => layer.type === 'ZWS' ? +f.elemId : f.id)
      }])
    }
  },
  watch: {
    groups: {
      deep: true,
      handler (groups) {
        this.$store.dispatch('updateMapGroups', { mapId: this.map.id, groups })
      }
    }
  }
}
</script>
