export const getInitialState = () => {
  return {
    activeChatID: null,
    archivedChatsShown: false,
    chats: [],
    filterByChatType: 'ALL',
    currentLoginUser: JSON.parse(localStorage.getItem('currentLoginUser')),
    currentLoginUserProjects: JSON.parse(localStorage.getItem('currentLoginUserProjects')),
    selectedProject: JSON.parse(localStorage.getItem('selectedProject')),
    establishedConnection: false,
    onlineUsers: [],
    activeModalChat: undefined,
    gisCredentials: undefined,
    collectiveData: { center: [0, 0], zoom: 6 },
    subscriptions: new Map(),
    returnToSearchLpDialog: false,
    projectPanelExpanded: false,
    mapViews: (() => {
      const prevViews = JSON.parse(window.localStorage.getItem('mapViews'))
      if (!prevViews) return []
      return prevViews.filter(el => el.date + 1000 * 3600 * 24 > Date.now())
    })(),
    mapLayersConfig: (() => {
      const prevState = JSON.parse(window.localStorage.getItem('mapLayersConfig'))
      if (!prevState) return []
      return prevState.filter(el => el.date + 1000 * 3600 * 24 > Date.now())
    })(),
    isFeatureCardCollapsed: false,
    mapGroups: [],
    etActionSettings: (() => {
      return JSON.parse(window.localStorage.getItem('etActionSettings')) || []
    })()
  }
}
